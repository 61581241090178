/* Dark Theme Contact.css */
.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 20px;
}

.contact-form {
  background-color: rgba(42, 42, 46, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 35px;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--text-primary, #f5f5f7);
  font-size: 0.95rem;
  letter-spacing: 0.02em;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 14px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  font-size: 1rem;
  color: var(--text-primary, #f5f5f7);
  transition: all 0.3s;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--accent-blue, #6e8efa);
  outline: none;
  box-shadow: 0 0 0 2px rgba(110, 142, 250, 0.3);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.form-group textarea {
  resize: vertical;
  min-height: 140px;
}

.submit-button {
  background-color: var(--accent-blue, #6e8efa);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s;
  width: 100%;
  letter-spacing: 0.02em;
  position: relative;
  overflow: hidden;
}

.submit-button:hover {
  background-color: rgba(110, 142, 250, 0.85);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(110, 142, 250, 0.3);
}

.submit-button:active {
  transform: translateY(1px);
}

.submit-button:disabled {
  background-color: rgba(153, 153, 153, 0.4);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Ripple effect for button */
.submit-button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.submit-button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(25, 25);
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.submit-message {
  margin-top: 25px;
  padding: 16px;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  animation: fadeIn 0.5s ease forwards;
}

.submit-message.success {
  background-color: rgba(21, 128, 61, 0.2);
  color: #4ade80;
  border: 1px solid rgba(21, 128, 61, 0.3);
}

.submit-message.error {
  background-color: rgba(182, 37, 37, 0.2);
  color: #f87171;
  border: 1px solid rgba(182, 37, 37, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .contact-form {
    padding: 25px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 12px;
  }
  
  .submit-button {
    padding: 12px 24px;
  }
}