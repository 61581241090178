/* Dark Theme LoginPage.css */
:root {
  --dark-bg: #1a1a1c;
  --dark-surface: #2a2a2e;
  --dark-elevated: #323236;
  --text-primary: #f5f5f7;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --accent-blue: #6e8efa;
  --accent-purple: #a78bfa;
  --accent-pink: #d85893;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

.login-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 20px;
}

.login-form-container {
  background-color: var(--dark-surface);
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
  padding: 35px;
  width: 100%;
  max-width: 450px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.login-title {
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
  color: var(--text-primary);
  font-size: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.login-form {
  margin-bottom: 25px;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--text-secondary);
  font-size: 0.95rem;
}

.form-group input {
  width: 100%;
  padding: 14px;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: 1rem;
  color: var(--text-primary);
  transition: all 0.2s;
}

.form-group input:focus {
  border-color: var(--accent-blue);
  outline: none;
  box-shadow: 0 0 0 2px rgba(110, 142, 250, 0.3);
}

.form-group input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.login-submit-button {
  width: 100%;
  padding: 14px;
  background-color: var(--accent-blue);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  letter-spacing: 0.01em;
  margin-top: 10px;
}

.login-submit-button:hover {
  background-color: rgba(110, 142, 250, 0.85);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.login-submit-button:active {
  transform: translateY(1px);
}

.login-submit-button:disabled {
  background-color: rgba(153, 153, 153, 0.4);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.login-help-text {
  margin-top: 24px;
  text-align: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.login-error {
  padding: 14px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: rgba(182, 37, 37, 0.2);
  color: #f87171;
  border: 1px solid rgba(182, 37, 37, 0.3);
  font-size: 0.9rem;
}

.login-success {
  padding: 14px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: rgba(21, 128, 61, 0.2);
  color: #4ade80;
  border: 1px solid rgba(21, 128, 61, 0.3);
  font-size: 0.9rem;
}

/* Login options styling */
.login-options {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.login-link {
  color: var(--accent-blue);
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.login-link:hover {
  color: rgba(110, 142, 250, 0.85);
  text-decoration: none;
  transform: translateY(-1px);
}

/* Profile page styles */
.profile-info {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.profile-field {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.profile-field:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.profile-label {
  font-weight: 500;
  color: var(--text-secondary);
}

.profile-value {
  color: var(--text-primary);
  font-weight: 400;
}

.profile-actions {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 25px;
}

.admin-button {
  width: 100%;
  padding: 14px;
  background-color: var(--accent-purple);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  text-decoration: none;
  display: block;
  letter-spacing: 0.01em;
}

.admin-button:hover {
  background-color: rgba(167, 139, 250, 0.85);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Animation for new elements */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.login-form-container {
  animation: fadeIn 0.5s ease-out;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .login-form-container {
    padding: 25px;
  }
  
  .login-title {
    font-size: 1.5rem;
  }
  
  .form-group input,
  .login-submit-button {
    padding: 12px;
  }
}