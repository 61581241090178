/* Dark Theme App.css - Base styles for the entire application */
:root {
  --dark-bg: #1a1a1c;
  --dark-surface: #2a2a2e;
  --dark-elevated: #323236;
  --text-primary: #f5f5f7;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --accent-blue: #6e8efa;
  --accent-purple: #a78bfa;
  --accent-pink: #d85893;
  --accent-green: #87c2a5;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark-bg);
  color: var(--text-primary);
  line-height: 1.5;
  overflow-x: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

a {
  text-decoration: none;
  color: var(--accent-blue);
  transition: color 0.2s;
}

a:hover {
  color: rgba(110, 142, 250, 0.8);
}

button {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  font-family: inherit;
}

/* Add subtle motion to the UI */
button, a {
  transition: all 0.2s ease;
}

button:active, a:active {
  transform: scale(0.97);
}

/* Frosted glass effect for cards and containers */
.glass-effect {
  background: rgba(42, 42, 46, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* Common container styles */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Button styles */
.btn {
  display: inline-block;
  padding: 12px 24px;
  border-radius: 30px;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.2s;
  text-align: center;
}

.btn-primary {
  background-color: var(--accent-blue);
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: rgba(110, 142, 250, 0.8);
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--text-primary);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.15);
  transform: translateY(-2px);
}

/* For forms and inputs */
input, textarea, select {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: var(--text-primary);
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
}

input:focus, textarea:focus, select:focus {
  border-color: var(--accent-blue);
  outline: none;
  box-shadow: 0 0 0 2px rgba(110, 142, 250, 0.3);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease forwards;
}