/* Dark Theme with Apple-inspired design and pastel accents */
:root {
  --dark-bg: #1a1a1c;
  --dark-surface: #2a2a2e;
  --dark-elevated: #323236;
  --text-primary: #f5f5f7;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --accent-blue: #6e8efa;
  --accent-purple: #a78bfa;
  --accent-pink: #d85893;
  --accent-green: #87c2a5;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

body {
  background-color: var(--dark-bg);
  color: var(--text-primary);
}

.home-container {
  width: 100%;
}

/* Hero Section - Dark theme */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 300px;
  background: linear-gradient(to bottom, var(--dark-elevated), var(--dark-bg));
  padding: 40px 20px;
}

.hero-content {
  max-width: 800px;
  margin: 0 auto;
}

.hero-title {
  font-size: 3rem;
  font-weight: 600;
  color: var(--text-primary);
  margin-bottom: 15px;
  letter-spacing: -0.02em;
}

.hero-subtitle {
  font-size: 1.3rem;
  color: var(--text-secondary);
  margin-bottom: 20px;
  line-height: 1.4;
}

/* Featured Section - Dark theme with pastel accents */
.featured-section {
  padding: 60px 20px;
  background-color: var(--dark-bg);
  max-width: 1200px;
  margin: 0 auto;
}

.featured-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.featured-item {
  flex: 1;
  background-color: var(--dark-surface);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 8px 30px var(--shadow-color);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 300px;
  max-width: 600px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.featured-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px var(--shadow-color);
}

.featured-item.his-blog {
  background: linear-gradient(135deg, rgba(110, 142, 250, 0.2), rgba(75, 108, 183, 0.2));
  border: 1px solid rgba(110, 142, 250, 0.3);
  color: var(--text-primary);
}

.featured-item.her-blog {
  background: linear-gradient(135deg, rgba(216, 88, 147, 0.2), rgba(208, 90, 145, 0.2));
  border: 1px solid rgba(216, 88, 147, 0.3);
  color: var(--text-primary);
}

.featured-content {
  padding: 40px;
  text-align: center;
  z-index: 1;
  width: 100%;
}

.featured-content h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: 600;
}

.featured-item.his-blog h3 {
  color: var(--accent-blue);
}

.featured-item.her-blog h3 {
  color: var(--accent-pink);
}

.featured-content p {
  margin-bottom: 30px;
  line-height: 1.5;
  font-size: 1.2rem;
  color: var(--text-secondary);
}

.learn-more {
  display: inline-block;
  padding: 12px 28px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.2s;
}

.his-blog .learn-more {
  background-color: rgba(110, 142, 250, 0.2);
  color: var(--accent-blue);
  border: 1px solid rgba(110, 142, 250, 0.3);
}

.his-blog .learn-more:hover {
  background-color: rgba(110, 142, 250, 0.3);
}

.her-blog .learn-more {
  background-color: rgba(216, 88, 147, 0.2);
  color: var(--accent-pink);
  border: 1px solid rgba(216, 88, 147, 0.3);
}

.her-blog .learn-more:hover {
  background-color: rgba(216, 88, 147, 0.3);
}

/* About Section - Dark theme */
.about-section {
  padding: 80px 20px;
  background-color: var(--dark-surface);
  text-align: center;
}

.section-header {
  text-align: center;
  margin-bottom: 40px;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--text-primary);
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin-bottom: 30px;
}

.contact-button {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--accent-blue);
  color: white;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
  transition: all 0.2s;
  border: none;
}

.contact-button:hover {
  background-color: rgba(110, 142, 250, 0.8);
  transform: translateY(-2px);
}

/* Responsive Adjustments */
@media (max-width: 900px) {
  .featured-grid {
    flex-direction: column;
    align-items: center;
  }
  
  .featured-item {
    width: 100%;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    min-height: 200px;
  }
  
  .hero-title {
    font-size: 2.5rem;
  }
  
  .hero-subtitle {
    font-size: 1.1rem;
  }
  
  .section-title {
    font-size: 2rem;
  }
  
  .featured-content h3 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 2rem;
  }
  
  .hero-subtitle {
    font-size: 1rem;
  }
  
  .featured-item {
    min-height: 300px;
  }
}