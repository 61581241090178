.navbar {
  background-color: rgba(26, 26, 28, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 44px;
  transition: all 0.3s ease;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}

.navbar.scrolled {
  background-color: rgba(26, 26, 28, 0.95);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 20px;
}

.navbar-logo {
  color: #f5f5f7;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 500;
}

.nav-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin: 0 10px;
}

.nav-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
  padding: 10px;
}

.nav-link:hover {
  color: #f5f5f7;
}

.user-menu {
  display: flex;
  align-items: center;
}

.user-dropdown {
  position: relative;
}

.user-button {
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 15px;
  transition: background-color 0.2s;
}

.user-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #f5f5f7;
}

.dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: #2a2a2e;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  min-width: 150px;
  margin-top: 10px;
  padding: 5px 0;
  z-index: 1000;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dropdown-item {
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.2s, color 0.2s;
}

.dropdown-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #f5f5f7;
}

.login-button {
  display: inline-block;
  padding: 6px 14px;
  background-color: #6e8efa;
  color: white;
  text-decoration: none;
  border-radius: 15px;
  font-size: 0.9rem;
  transition: background-color 0.2s, transform 0.2s;
}

.login-button:hover {
  background-color: rgba(110, 142, 250, 0.8);
  transform: translateY(-1px);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 20px;
  position: relative;
}

.menu-toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #f5f5f7;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.menu-toggle span:nth-child(1) {
  top: 0px;
}

.menu-toggle span:nth-child(2) {
  top: 9px;
}

.menu-toggle span:nth-child(3) {
  top: 18px;
}

.menu-toggle.open span:nth-child(1) {
  top: 9px;
  transform: rotate(135deg);
}

.menu-toggle.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.menu-toggle.open span:nth-child(3) {
  top: 9px;
  transform: rotate(-135deg);
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    z-index: 1001;
  }
  
  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background-color: #2a2a2e;
    flex-direction: column;
    padding-top: 60px;
    transition: right 0.3s ease;
    box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
  }
  
  .nav-menu.open {
    right: 0;
  }
  
  .nav-item {
    margin: 0;
    width: 100%;
  }
  
  .nav-link {
    display: block;
    padding: 15px 20px;
    font-size: 1.1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
}