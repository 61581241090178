/* Dark Theme Comment.css */
:root {
  --dark-bg: #1a1a1c;
  --dark-surface: #2a2a2e;
  --dark-elevated: #323236;
  --text-primary: #f5f5f7;
  --text-secondary: rgba(255, 255, 255, 0.7);
  --accent-blue: #6e8efa;
  --accent-purple: #a78bfa;
  --accent-pink: #d85893;
  --accent-green: #87c2a5;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

.comment {
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 12px;
  margin-bottom: 16px;
  background-color: rgba(42, 42, 46, 0.6);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.comment:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.comment-author {
  font-weight: 600;
  color: var(--accent-blue);
  font-size: 0.95rem;
}

.comment-date {
  font-size: 0.8rem;
  color: var(--text-secondary);
  opacity: 0.8;
}

.comment-content {
  color: var(--text-primary);
  line-height: 1.5;
  font-size: 0.95rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

/* Animation for new comments */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.comment-new {
  animation: fadeIn 0.4s ease-out;
}

/* When a comment is being deleted */
.comment-deleting {
  opacity: 0.5;
  pointer-events: none;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .comment {
    padding: 12px;
  }
  
  .comment-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
}