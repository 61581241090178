.admin-page-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .admin-page-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
    text-align: center;
  }
  
  .loading {
    text-align: center;
    padding: 40px;
    font-size: 1.2rem;
    color: #666;
  }