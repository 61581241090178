/* Dark Theme Footer.css */
.footer {
  background-color: var(--dark-elevated);
  padding: 40px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-section {
  margin-bottom: 30px;
  min-width: 200px;
  flex: 1;
}

.footer-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--text-primary);
  position: relative;
  padding-bottom: 10px;
}

.footer-title:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: var(--accent-blue);
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 12px;
}

.footer-links a {
  color: var(--text-secondary);
  text-decoration: none;
  transition: color 0.2s;
  font-size: 0.95rem;
}

.footer-links a:hover {
  color: var(--accent-blue);
}

.footer-bottom {
  padding-top: 30px;
  text-align: center;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 30px;
}

.footer-copyright {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.footer-social {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  justify-content: center;
}

.social-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.05);
  color: var(--text-secondary);
  transition: all 0.2s;
}

.social-icon:hover {
  background-color: var(--accent-blue);
  color: white;
  transform: translateY(-3px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
  }
  
  .footer-section {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .footer-section:last-child {
    margin-bottom: 20px;
  }
}