.comment-section {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid #eee;
  }
  
  .comment-section-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .comment-form {
    margin-bottom: 30px;
  }
  
  .comment-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    font-family: inherit;
    font-size: 1rem;
    resize: vertical;
  }
  
  .comment-input:focus {
    outline: none;
    border-color: #0071e3;
  }
  
  .comment-submit {
    background-color: #0071e3;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }
  
  .comment-submit:hover {
    background-color: #0058b3;
  }
  
  .comment-submit:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
  
  .comment-login-message {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
    color: #6c757d;
  }
  
  .comment-error {
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .comments-list {
    margin-top: 20px;
  }
  
  .no-comments {
    text-align: center;
    padding: 20px;
    color: #6c757d;
    background-color: #f8f9fa;
    border-radius: 8px;
  }